import React from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import { isSessionValid } from 'src/core/login/LoginService';

import VimeoPlayer from 'src/components/vimeo-player/VimeoPlayer';

import './liveStream.scss';

const LiveStreamPage = ({ actions, pageTitle, queryString, labels, item }) => {
  if (!(config.PLAYER_EMBED_VIMEOLIVE && config.PLAYER_EMBED_VIMEOLIVE.FEATURE_ENABLED)) {
    console.warn('FEATURES PLAYER_EMBED_VIMEOLIVE DISABLED: TRY TO ACTIVATE IT IN CONFIG DATA');
    return <></>;
  }

  return (
    <div className="liveStream_container">
      <VimeoPlayer
        title={pageTitle}
        queryString={queryString}
        mediaUrl={item.lump && item.lump.MediaUrl}
        labels={labels}
        location={item.event_place_text}
        actions={actions}
      />
    </div>
  );
};

LiveStreamPage.propTypes = {
  actions: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  labels: PropTypes.object,
  location: PropTypes.string,
  queryString: PropTypes.string,
};

export default LiveStreamPage;
