import { UNAUTHORIZED_PAGE_KEY } from 'src/pages/pagesKeys';

import UnauthorizedPage from './UnauthorizedPage';
import reducer from './UnauthorizedPageReducer';

export default {
  key: UNAUTHORIZED_PAGE_KEY,
  path: '/unauthorized',
  elId: 'unauthorized-page',
  className: 'unauthorized-page',
  component: UnauthorizedPage,
  relatedDataToFetch: [],
  getReduxReducer: (state, action) => reducer(state[UNAUTHORIZED_PAGE_KEY], action),
};
