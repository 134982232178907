import { getParameterByName } from 'src/core/util/JsTools';
import { getBindedActions } from 'src/store/bindedActions';
import config from '../../../data/config/config';
import fetchHelper from 'src/core/util/FetchHelper';
/* import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { get as getLabels } from 'src/core/Lang'; */
import {
  wipeToken,
  setUserData,
  wipeUserData,
  getToken,
  setToken,
  getStream,
  getTokenExpirationTime,
  // setStream,
  wipeStream,
  getNextRoute,
  wipeNextRoute,
} from './OAuth2Persistence';

import {logout} from 'src/core/login/LoginService.js';

const LOG_PREF = '[OAuth2] ';
const QUERY_PARAMETER_CODE = 'code';
const BEARER = 'Bearer ';

let refreshTokenId = null,
  streamTimoutId = null;

const {
  authorization_url,
  client_id,
  redirect_uri,
  client_secret,
  response_type,
  eventcode,
  community_code,
  providerID,
  WEB_LINK_TARGET,
  userData,
} = config.OAuth2;

export function loginOAuth2() {
  console.log(`${LOG_PREF} loginOAuth2`);
  let inAppBrowserRef;
  const authenteUrl = `${authorization_url}/StartOAuthLogin.aspx?client_id=${client_id}&redirect_uri=${redirect_uri}&eventcode=${eventcode}`;
  if (global.isCordovaContext) inAppBrowserRef = cordova.InAppBrowser.open(authenteUrl, '_blank');
  else inAppBrowserRef = window.open(authenteUrl, WEB_LINK_TARGET);

  inAppBrowserRef.addEventListener('loadstart', function(evt) {
    if (evt.url.indexOf(redirect_uri) == 0) {
      getCodeFromRedirectUri(evt.url);
      inAppBrowserRef.close();
    }
  });
  inAppBrowserRef.addEventListener('loadstop', function(evt) {
    if (evt.url.indexOf(redirect_uri) == 0) {
      getCodeFromRedirectUri(evt.url);
      inAppBrowserRef.close();
    }
  });
  inAppBrowserRef.addEventListener('loaderror', function(err) {
    console.error('inAppBrowser error ' + err);
  });
}

const getCodeFromRedirectUri = (url) => {
  const bcomCode = getParameterByName(QUERY_PARAMETER_CODE, url);

  if (bcomCode) {
    getBindedActions().getTokenOnLogInOAuth2(bcomCode);
  }
};

export const onUserLogedInOAuth2 = (data) => {
  console.log(`${LOG_PREF} user data`, data);
  const nextRoute = getNextRoute();
  wipeNextRoute();
  if (nextRoute) {
    ///BEURKKKK
    const pageKey = getStream() && getStream().pageKey;
    const propsStream = getStream() && getStream().props;

    getBindedActions().navigate(nextRoute.pageKey, { ...nextRoute.pageProps, ...propsStream });

    ///BEURKKKK
    streamTimoutId && clearTimeout(streamTimoutId);
    streamTimoutId = setTimeout(() => {
      wipeStream();
    }, 4000);
  }
};

export const onClickLogInOAuth2 = (code) => {
  if (code) {
    getAccessToken(code);
  }
};

export const logOutOAuth2 = () => {
  console.log(`${LOG_PREF}logOutOAuth2`);
  getBindedActions().setLoginOAuth2Status(false, null, null);
  wipeToken();
  wipeUserData();
  wipeStream();
  stopKeepTokenAlive();
  getBindedActions().navigateToHome();
};

const _setUserData = (data) => {
  const userInfo = userData(data);
  getBindedActions().setLoginStatus(true, userData, null);
  getBindedActions().userDataUpdated(userInfo);
  setUserData(userInfo);
};

export const getAccessToken = (code) => {
  console.log(`${LOG_PREF}getAccessToken`);

  const url = `${authorization_url}/API/GetAccessToken?redirect_uri=${redirect_uri}&client_secret=${client_secret}&code=${code}&client_id=${client_id}`;
  fetchHelper(
    url,
    {
      method: 'GET',
    },
    true, // is json
    // on success:
    (json) => {
      console.log(`${LOG_PREF}getAccessToken success`, json);
      setToken(json);
      keepTokenAlive();
      getUserInfo(json.access_token);
    },
    (err) => {
      onfailure(err);
    }, // on failure
    false // no error message
  );
};

export const getUserInfo = (access_token) => {
  console.log(`${LOG_PREF}getUserInfo`, access_token);

  const url = `${authorization_url}/API/GetUserInfo`;
  fetchHelper(
    url,
    {
      method: 'GET',
      headers: [{ name: 'Authorization', value: BEARER + access_token }],
    },
    true, // is json
    // on success:
    (json) => {
      console.log(`${LOG_PREF}getUserInfo success`, json);
      console.log(`${LOG_PREF}getParticipantInfo`, access_token);
      const url = `${authorization_url}/API/GetParticipantInfo?event_code=${eventcode}`;
      fetchHelper(
        url,
        {
          method: 'GET',
          headers: [{ name: 'Authorization', value: BEARER + access_token }],
        },
        true, // is json
        // on success:
        (_json) => {
          json.isViewer = false;
          console.log(`${LOG_PREF}getParticipantInfo success`, _json);
          const payment_status = _json.payment_status.toUpperCase();
          const registration_status = _json.registration_status.toUpperCase();
          // to do check payment_status and registration_status variable in prod env
          if (
            ['PAID', 'NOTAPPLICABLE', 'OVERPAID', 'NOTINVOICED'].includes(payment_status) &&
            ['CONFIRMED'].includes(registration_status)
          ) {
            json.isViewer = true;
          }
          _setUserData(json);
          getBindedActions().setLoginOAuth2Status(true, json, null);
        },
        (err) => {
          console.error('could not get participant info');
          getBindedActions().setLoginOAuth2Status(true, json, null);
          onfailure(err);
        }, // on failure
        false // no error message
      );
    },
    (err) => {
      logout();
      onfailure(err);
    }, // on failure
    false // no error message
  );
};

export const getCommunityMemberInfo = (access_token) => {
  console.log(`${LOG_PREF}getCommunityMemberInfo`, access_token);

  const url = `${authorization_url}/API/GetCommunityMemberInfo?community_code=${eventcode}`;
  fetchHelper(
    url,
    {
      method: 'GET',
      headers: [{ name: 'Authorization', value: BEARER + access_token }],
    },
    true, // is json
    // on success:
    (json) => {
      console.log(`${LOG_PREF} getCommunityMemberInfo`, json);
    },
    (err) => {
      onfailure(err);
    }, // on failure
    false // no error message
  );
};

export const keepTokenAlive = () => {
  refreshTokenId && clearTimeout(refreshTokenId);
  const currentTs = Math.round(new Date().getTime() / 1000);
  const tokenExpirationTime = getTokenExpirationTime();
  if (
    !getToken() ||
    !getToken().access_token ||
    tokenExpirationTime == undefined ||
    !tokenExpirationTime ||
    tokenExpirationTime < currentTs
  ) {
    getBindedActions().refreshToken();
  } else {
    getUserInfo(getToken().access_token);
    const diff = tokenExpirationTime - currentTs;
    const margin = 30;
    const _timeout = diff - margin;
    const timeout = _timeout * 1000;
    refreshTokenId = setTimeout(() => {
      getBindedActions().refreshToken();
    }, timeout);
  }
};

export const stopKeepTokenAlive = () => {
  refreshTokenId && clearTimeout(refreshTokenId);
  refreshTokenId = null;
};

export const refreshAccessToken = () => {
  const refresh_token = getToken() && getToken().refresh_token;
  console.log(`${LOG_PREF}refreshAccessToken`, refresh_token);
  if (refresh_token) {
    const url = `${authorization_url}/API/RefreshAccessToken?client_id=${client_id}&client_secret=${client_secret}&refresh_token=${refresh_token}`;
    fetchHelper(
      url,
      {
        method: 'GET',
      },
      true, // is json
      // on success:
      (json) => {
        console.log(`${LOG_PREF}refreshAccessToken success`, json);
        setToken(json);
        keepTokenAlive();
        getUserInfo(json.access_token);
      },
      (err) => {
        console.log(`${LOG_PREF}refreshAccessToken err`, err);
        logout();
        onfailure(err);
      }, // on failure
      false // no error message
    );
  }
};

const onfailure = (err) => {
  console.error(`${LOG_PREF} error`, err);
  //refreshAccessToken();
};
