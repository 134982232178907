import {
  PERFORM_LOGIN_OAUTH2,
  SET_LOGIN_OAUTH2_STATUS,
  PERFORM_LOGOUT_OAUTH2,
  GET_TOKEN_OAUTH2,
  REFRESH_TOKEN_OAUTH2,
  CORDOVA_RESUME,
} from 'src/store/actionTypes';

import {
  loginOAuth2,
  onUserLogedInOAuth2,
  onClickLogInOAuth2,
  logOutOAuth2,
  refreshAccessToken,
  keepTokenAlive
} from './OAuth2service';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case SET_LOGIN_OAUTH2_STATUS:
      if (action.loggedInOAuth2) {
        onUserLogedInOAuth2(action.OAuth2Data);
      }
      break;
    case GET_TOKEN_OAUTH2:
      onClickLogInOAuth2(action.OAuth2Code);
      break;
    case REFRESH_TOKEN_OAUTH2:
      refreshAccessToken();
      break;
    case PERFORM_LOGIN_OAUTH2:
      loginOAuth2();
      break;
    case PERFORM_LOGOUT_OAUTH2:
      logOutOAuth2();
      break;
    case CORDOVA_RESUME:
      keepTokenAlive();
      break;
    default: // for linter
  }

  return result;
};
