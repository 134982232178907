import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from 'data/config/config';
import './VimeoPlayer.scss';

const LOG_PREF = '[VimeoPlayer] ';

class VimeoPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  convertStreamUrl = (url) => {
    if (url) {
      // https://developer.vimeo.com/player/sdk/embed more params vimeo
      const params = url.split('/');
      if (params[3] && params[4]) {
        let _url = `https://player.vimeo.com/video/${params[3]}?h=${params[4]}&amp;autoplay=1&amp;`;
        return _url;
      }
      this.hideSpinner();
      return '';
    }
    this.hideSpinner();
    return '';
  };

  render() {
    const { mediaUrl } = this.props;
    const { loading } = this.state;

    if (!config.PLAYER_EMBED_VIMEOLIVE.FEATURE_ENABLED) {
      return null;
    }
    const _mediaUrl = this.convertStreamUrl(mediaUrl);
    if (!_mediaUrl) {
      return null; // to do add message i18n when no mediaUrl found
    }

    return (
      <div className="embed-container">
        {loading ? <div className="spinner"></div> : null}
        <iframe
          src={_mediaUrl}
          onLoad={this.hideSpinner}
          frameBorder="0"
          webkitallowfullscreen={'true'}
          mozallowfullscreen={'true'}
          allowFullScreen={true}
          data-vimeo-responsive
        ></iframe>
      </div>
    );
  }
}

VimeoPlayer.propTypes = {
  mediaUrl: PropTypes.string,
  // common props
  labels: PropTypes.object,
  actions: PropTypes.object,
};

export default VimeoPlayer;
