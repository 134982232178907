import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { UNAUTHORIZED_PAGE_KEY } from 'src/pages/pagesKeys';
import Description from 'src/components/fiche/Description';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import EmailRow from 'src/components/fiche/EmailRow';
import * as actions from 'src/store/actions';

import '../common-fiche.scss';
import './UnauthorizedPage.scss';

class UnauthorizedPage extends Component {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };

  render() {
    return (
      <>
        <Menu
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={UNAUTHORIZED_PAGE_KEY}
          actions={this.props.actions}
          options={this.props.menuOptions}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          pageKey={UNAUTHORIZED_PAGE_KEY}
          title={this.props.labels.unauthorized.title}
          actions={this.props.actions}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={false}
          hasMenuButton={this.props.menuButtonInToolbar}
        />
        <div className="fiche unauthorized content-font content-below-apptoolbar">
          <DetailFixedTitle
            title={this.props.labels.unauthorized.title}
            hideFav={true}
            labels={this.props.labels}
          ></DetailFixedTitle>

          <div className="content" ref={this.setScrollableContentEl}>
            <div className="all-informations content-font">
              <Description
                //logoFileName={logoUrl}
                description={this.props.labels.unauthorized.description}
                //isLogoFullWidth={this.props.isLogoFullWidth}
              />
              {this.props.labels.unauthorized.email && <EmailRow email={this.props.labels.unauthorized.email} />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

UnauthorizedPage.propTypes = {
  actions: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  labels: PropTypes.object,
  location: PropTypes.string,
  queryString: PropTypes.string,
};

export const mapStateToProps = (state) => state[UNAUTHORIZED_PAGE_KEY];

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    onClick(action) {
      if (typeof action === 'function') {
        return action(dispatch);
      } else {
        console.error('Unexpected `action` argument: ', action);
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedPage);
