import config from 'data/config/config';

const LOCALSTORAGE_LOGIN_KEY = 'loginTokenOAuth2';
const LOCALSTORAGE_USERDATA_KEY = 'userData';
const LOCALSTORAGE_STREAM_KEY = 'stream';
const LOCALSTORAGE_NEXTROUTE_KEY = 'nextRoute';
const LOCALSTORAGE_TOKENEXPIRATION_TIME = 'tokenExpirationTime';

/**
 * TOKEN getter
 * @return {string}
 */
export function getToken() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOGIN_KEY));
  return data || null;
}

/**
 * TOKEN setter
 * @param {string} value
 */
export function setToken(value) {
  window.localStorage.setItem(LOCALSTORAGE_LOGIN_KEY, JSON.stringify(value));
  const expiresIn = (value && value.expires_in) || 0;
  const expirationTime = Math.round(new Date().getTime() / 1000) + expiresIn;
  window.localStorage.setItem(LOCALSTORAGE_TOKENEXPIRATION_TIME, JSON.stringify(expirationTime));
}

/**
 * TOKEN wipe
 */
export function wipeToken() {
  window.localStorage.removeItem(LOCALSTORAGE_LOGIN_KEY);
}


/**
 * TOKEN EXPIRATION TIME getter
 * @return {number}
 */
 export function getTokenExpirationTime() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_TOKENEXPIRATION_TIME));
  return data || null;
}

/**
 * USER DATA getter
 * @return {object}
 */
export function getUserData() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USERDATA_KEY));

  return data || null;
}

/**
 * USER DATA setter
 * @param {object}
 */
export function setUserData(value) {
  window.localStorage.setItem(LOCALSTORAGE_USERDATA_KEY, JSON.stringify(value));
}

/**
 * USER DATA wipe
 * @param {object}
 */
export function wipeUserData() {
  window.localStorage.removeItem(LOCALSTORAGE_USERDATA_KEY);
}

/**
 * stream getter
 * @return {object}
 */
export function getStream() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_STREAM_KEY));

  return data || null;
}

/**
 * nextRoute getter
 * @return {object}
 */
export function getNextRoute() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_NEXTROUTE_KEY));

  return data || null;
}

/**
 * stream setter
 * @param {object}
 */
export function setStream(value) {
  window.localStorage.setItem(LOCALSTORAGE_STREAM_KEY, JSON.stringify(value));
}

/**
 * nextRoute setter
 * @param {object}
 */
export function setNextRoute(value) {
  window.localStorage.setItem(LOCALSTORAGE_NEXTROUTE_KEY, JSON.stringify(value));
}

/**
 * stream wipe
 * @param {object}
 */
export function wipeStream() {
  window.localStorage.removeItem(LOCALSTORAGE_STREAM_KEY);
}

/**
 * nextRoute wipe
 * @param {object}
 */
export function wipeNextRoute() {
  window.localStorage.removeItem(LOCALSTORAGE_NEXTROUTE_KEY);
}
