import { NAVIGATION_CONFIRMED } from 'src/store/actionTypes';

import { clearSearchResults, setSearchFieldVisible } from 'src/store/actions';

import { HISTORY_ACTIONS } from 'src/core/navigation/History';

import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case NAVIGATION_CONFIRMED:
      // Scenario:
      //  - on a list: perform a contextual search
      //  - without clearing the search nor clicking on a result, navigate somewhere else (e.g home)
      //  - navigate again to the list where the search was performed
      //  -> Search results are still visible

      // Ideally we clear search results only when navigating forward (!action.historyAction) to a list page
      if (
        (action.pageKey === LIST_PAGE_KEY || action.pageKey === LIST_GROUPS_PAGE_KEY) &&
        action.historyAction === HISTORY_ACTIONS.PUSH
      ) {
        dispatch(clearSearchResults(action.pageKey));
        dispatch(setSearchFieldVisible(action.pageKey, false));
      }
      break;

    default:
  }
  return result;
};
