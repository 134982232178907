import { prefixWith } from 'src/store/reducers/utils';

/**
 * Convention:
 *
 *   [subject]_[past participle verb]  =>  result of an action, event
 *                                           e.g. DATA_ASSETS_UPDATED, LANG_CHANGED, WINDOW_RESIZED
 *
 *   [infinitive verb]_[subject]  =>  request for action
 *                                      e.g. FETCH_FAVORITES, SHOW_LANG_DIALOG
 */

export const DATA_ASSETS_UPDATING = 'DATA_ASSETS_UPDATING';
export const DATA_ASSETS_UPDATED = 'DATA_ASSETS_UPDATED';

export const MAP_LOADED = 'MAP_LOADED';
export const MAP_RELOAD = 'MAP_RELOAD';
export const MAP_SHOW_PLACE = 'MAP_SHOW_PLACE';
export const MAP_IS_RESTARTING = 'MAP_IS_RESTARTING';
export const MAP_USER_LOCATED = 'MAP_USER_LOCATED';
export const MAP_USER_UNLOCATED = 'MAP_USER_UNLOCATED';
export const SHOW_MAP_ITINERARY = 'SHOW_MAP_ITINERARY';
export const DISPATCH_ITINERARY = 'DISPATCH_ITINERARY ';
export const SHOW_SEARCH_PLACE_DIALOG = 'SHOW_SEARCH_PLACE_DIALOG';
export const HIDE_SEARCH_PLACE_DIALOG = 'HIDE_SEARCH_PLACE_DIALOG';
export const FETCH_GMAP_PLACES = 'FETCH_GMAP_PLACES';

export const SET_SEARCH_PLACE_DIALOG_CANCELABLE = 'SET_SEARCH_PLACE_DIALOG_CANCELABLE';
export const MAP_ZOOM_ON_ZONE = 'MAP_ZOOM_ON_ZONE';
export const ITINERARY_API_CALLED = 'ITINERARY_API_CALLED';
export const MOBIGEO_ERROR_THROWN = 'MOBIGEO_ERROR_THROWN';
export const MAP_FAVORITE_CREATED = 'MAP_FAVORITE_CREATED';
export const MAP_FAVORITE_SHARED = 'MAP_FAVORITE_SHARED';
export const GEOGROUP_PSEUDO_SET = 'GEOGROUP_PSEUDO_SET';
export const GEOGROUP_GROUP_CREATED = 'GEOGROUP_GROUP_CREATED';
export const GEOGROUP_GROUP_JOINED = 'GEOGROUP_GROUP_JOINED';
export const GEOGROUP_GROUP_QUITTED = 'GEOGROUP_GROUP_QUITTED';
export const REQUEST_LOCATION_CAPABILITIES = 'REQUEST_LOCATION_CAPABILITIES';
export const STOP_LOCATION = 'STOP_LOCATION';
export const TOGGLE_LOCATION_STATUS = 'TOGGLE_LOCATION_STATUS';
export const TOGGLE_LOCATION_CAPABILITY_STATUS = 'TOGGLE_LOCATION_CAPABILITY_STATUS';
export const TOGGLE_PMR_STATUS = 'TOGGLE_PMR_STATUS';

export const UPDATER_INITIALIZED = 'UPDATER_INITIALIZED';

export const CONFIG_JSON_LOADED = 'CONFIG_JSON_LOADED';

export const POLL_CONFIG_UPDATED = 'POLL_CONFIG_UPDATED';
export const POLL_CONFIG_LOADED = 'POLL_CONFIG_LOADED';
export const VALIDATE_POLL = 'VALIDATE_POLL';
export const VALIDATE_POLL_CODE = 'VALIDATE_POLL_CODE';
export const SET_POLL_ID = 'SET_POLL_ID';
export const SET_POLL_CODE = 'SET_POLL_CODE';
export const SHOW_POLL_DIALOG = 'SHOW_POLL_DIALOG';
export const HIDE_POLL_DIALOG = 'HIDE_POLL_DIALOG';
export const GO_TO_NEXT_POLL_STEP = 'GO_TO_NEXT_POLL_STEP';
export const GO_TO_PREVIOUS_POLL_STEP = 'GO_TO_PREVIOUS_POLL_STEP';
export const SUBMIT_POLL = 'SUBMIT_POLL';
export const SET_POLL_STEP = 'SET_POLL_STEP';
export const SET_POLL_ERROR = 'SET_POLL_ERROR';
export const SET_POLL_PAGE = 'SET_POLL_PAGE';
export const SET_POLL_MARK = 'SET_POLL_MARK';
export const SET_POLL_COMMENT = 'SET_POLL_COMMENT';
export const SET_POLL_CHOICE = 'SET_POLL_CHOICE';
export const SET_POLL_MULTIPLE = 'SET_POLL_MULTIPLE';

export const FETCH_CONTRIBUTIONS_FEED = 'FETCH_CONTRIBUTIONS_FEED';
export const CONTRIBUTIONS_FEED_LOADED = 'CONTRIBUTIONS_FEED_LOADED';
export const SET_CONTRIBUTIONS_FEED_ERROR = 'SET_CONTRIBUTIONS_FEED_ERROR';
export const ACTIVATE_CONTRIBUTIONS_REFRESH = 'ACTIVATE_CONTRIBUTIONS_REFRESH';

export const FETCH_SOCIAL_FEED = 'FETCH_SOCIAL_FEED';
export const SOCIAL_FEED_LOADED = 'SOCIAL_FEED_LOADED';
export const SET_SOCIAL_FEED_ERROR = 'SET_SOCIAL_FEED_ERROR';

export const NAVIGATE = 'NAVIGATE';
export const NAVIGATION_CONFIRMED = 'NAVIGATION_CONFIRMED';
export const NAVIGATE_BACK = 'NAVIGATE_BACK';
export const NAVIGATE_TO_ITEMS = 'NAVIGATE_TO_ITEMS';
export const HAS_NAVIGATED = 'HAS_NAVIGATED';

export const ITEM_BEING_FETCHED = 'ITEM_BEING_FETCHED';
export const ITEM_FETCHED = 'ITEM_FETCHED';

export const ITEMS_FETCHED = 'ITEMS_FETCHED';

export const LISTS_FETCHED = 'LISTS_FETCHED';

export const GROUPED_ITEMS_FETCHED = 'GROUPED_ITEMS_FETCHED';

export const PROFILE_CHANGED = 'PROFILE_CHANGED';

export const LANG_CHANGED = 'LANG_CHANGED';

export const SHOW_DISCLAIMER = 'SHOW_DISCLAIMER';

export const SHOW_LANG_DIALOG = 'SHOW_LANG_DIALOG';
export const HIDE_LANG_DIALOG = 'HIDE_LANG_DIALOG';

export const PERFORM_LOGIN = 'PERFORM_LOGIN';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';

export const UPDATE_USER_DATA_REQUEST_STATUS = 'UPDATE_USER_DATA_REQUEST_STATUS';
export const USER_DATA_UPDATED = 'USER_DATA_UPDATED';

export const SHOW_SHARE_DIALOG = 'SHOW_SHARE_DIALOG';
export const HIDE_SHARE_DIALOG = 'HIDE_SHARE_DIALOG';

export const SHOW_NOTE_MODAL = 'SHOW_NOTE_MODAL';
export const HIDE_NOTE_MODAL = 'HIDE_NOTE_MODAL';
export const NOTE_CREATED = 'NOTE_CREATED';
export const SAVING_NOTE = 'SAVING_NOTE';
export const NOTE_SAVED = 'NOTE_SAVED';
export const DELETING_NOTE = 'DELETING_NOTE';
export const NOTE_DELETED = 'NOTE_DELETED';
export const GET_NOTE = 'GET_NOTE';
export const EXPORT_NOTES = 'EXPORT_NOTES';
export const FETCH_NOTES = 'FETCH_NOTES';

export const SHOW_FORM_MODAL = 'SHOW_FORM_MODAL';
export const HIDE_FORM_MODAL = 'HIDE_FORM_MODAL';

export const UPDATE_PAGE_STATE = 'UPDATE_PAGE_STATE';

export const CONTACT_SAVED_TO_DEVICE = 'CONTACT_SAVED_TO_DEVICE';

export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const FETCH_FAVORITES = 'FETCH_FAVORITES';
export const ALL_FAVORITES_DELETED = 'ALL_FAVORITES_DELETED';
export const SET_FAVORITES_SYNCHRONIZATION_STATUS = 'SET_FAVORITES_SYNCHRONIZATION_STATUS';
export const SYNCHRO_FAVORITES_ICON_CLICKED = 'SYNCHRO_FAVORITES_ICON_CLICKED';
export const SET_CODE_IDENTIFICATION = 'SET_CODE_IDENTIFICATION';
export const SET_TEMPORARY_CODE_IDENTIFICATION = 'SET_TEMPORARY_CODE_IDENTIFICATION';
export const SHOW_FAVORITES_CODE_DIALOG = 'SHOW_FAVORITES_CODE_DIALOG';
export const HIDE_FAVORITES_CODE_DIALOG = 'HIDE_FAVORITES_CODE_DIALOG';
export const FETCH_FAVORITES_CODE = 'FETCH_FAVORITES_CODE';
export const SYNC_WITH_FAVORITES_CODE = 'SYNC_WITH_FAVORITES_CODE';
export const SET_FAVORITES_CODE_SYNC_STEP = 'SET_FAVORITES_CODE_SYNC_STEP';

export const WINDOW_RESIZED = 'WINDOW_RESIZED';

export const SEARCH_PERFORMED = 'SEARCH_PERFORMED';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const PLACE_SEARCH_PERFORMED = 'PLACE_SEARCH_PERFORMED';
export const SEARCHED_PLACE_SELECTED = 'SEARCHED_PLACE_SELECTED';
export const CLEAR_PLACE_RESULTS = 'CLEAR_PLACE_RESULTS';
export const SET_SEARCH_FIELD_VISIBLE = 'SET_SEARCH_FIELD_VISIBLE';

export const TOGGLE_MENU = 'TOGGLE_MENU';

export const SHOW_DATA_LIST_DIALOG = 'SHOW_DATA_LIST_DIALOG';
export const HIDE_DATA_LIST_DIALOG = 'HIDE_DATA_LIST_DIALOG';

export const SHOW_INTERSTICIEL = 'SHOW_INTERSTICIEL';
export const HIDE_INTERSTICIEL = 'HIDE_INTERSTICIEL';
export const SHOW_INTERSTICIEL_CLOSE_BUTTON = 'SHOW_INTERSTICIEL_CLOSE_BUTTON';
export const HIDE_INTERSTICIEL_CLOSE_BUTTON = 'HIDE_INTERSTICIEL_CLOSE_BUTTON';

export const SHOW_FULL_LOADER = 'SHOW_FULL_LOADER';
export const HIDE_FULL_LOADER = 'HIDE_FULL_LOADER';

export const AD_CLICKED = 'AD_CLICKED';
export const LINK_CLICKED = 'LINK_CLICKED';

export const SHOW_NOTIF = 'SHOW_NOTIF';
export const EDIT_NOTIF = 'EDIT_NOTIF';
export const REMOVE_NOTIF = 'REMOVE_NOTIF';

// actions related to app / device state
const prefixCordova = prefixWith('cordova');
export const CORDOVA_PAUSE = prefixCordova('pause'); // used as status : app paused
export const CORDOVA_RESUME = prefixCordova('resume');
export const CORDOVA_READY = prefixCordova('ready'); // used as status : device ready
export const CORDOVA_ACTIVE = prefixCordova('active'); // used as status : app active in fg

// notif showed up
const pNotif = prefixWith('cordova-notif');
export const NOTIF_TRIGGERED = pNotif('triggered');

// user interacted with a notif to clear them
export const NOTIF_USER_CLEAR = pNotif('user-clear');
export const NOTIF_USER_CLEAR_ALL = pNotif('user-clear-all');

// user clicked on notif
export const NOTIF_CLICK = pNotif('user-click');

// cancel a scheduled notif
export const NOTIF_CANCEL = pNotif('cancel');
export const NOTIF_CANCEL_ALL = pNotif('cancel-all');

// schedule
export const NOTIF_SCHEDULE = pNotif('schedule');

// Flight schedule
const flightSchedule = prefixWith('flights_schedule');
export const FETCHED_DATA = flightSchedule('fetched_data');
export const REFRESH_DATA = flightSchedule('refresh_data');
export const FAILED = flightSchedule('failed');
export const RESTORE_DATA = flightSchedule('restore_from_cache');
export const STORED_DATA = flightSchedule('stored_data');
export const UPDATE_AIRCRAFTS = flightSchedule('update_aircrafts');
export const FLIGHT_CLICKED = flightSchedule('flight_clicked');

export const SEND_APPOINTMENT_REQUEST = 'SEND_APPOINTMENT_REQUEST';
export const APPOINTMENT_REQUEST_SENT = 'APPOINTMENT_REQUEST_SENT';
export const APPOINTMENT_REQUEST_SEND_RESULT = 'APPOINTMENT_REQUEST_SEND_RESULT';

export const CONTACT_REQUEST_PERFORMED = 'CONTACT_REQUEST_PERFORMED';

export const REAL_TIME_CONNECTED = 'REAL_TIME_CONNECTED';
export const REAL_TIME_DISCONNECTED = 'REAL_TIME_DISCONNECTED';

export const SHOW_FILTER_DIALOG = 'SHOW_FILTER_DIALOG';
export const HIDE_FILTER_DIALOG = 'HIDE_FILTER_DIALOG';
export const FILTER_TOP_CAT_SELECTED = 'FILTER_TOP_CAT_SELECTED';
export const FILTER_CATEGORY_TOGGLE = 'FILTER_CATEGORY_TOGGLE';
export const FILTER_RESET = 'FILTER_RESET';

export const TAIGA_SEARCH_ONGOING = 'TAIGA_SEARCH_ONGOING';
export const TAIGA_SEARCH_PERFORMED = 'TAIGA_SEARCH_PERFORMED';
export const TAIGA_SEARCH_CLEARED = 'TAIGA_SEARCH_CLEARED';

export const SYNOPTIC_AGENDA_TAB_INDEX_UPDATE = 'SYNOPTIC_AGENDA_TAB_INDEX_UPDATE';

export const KEYBOARD_TOGGLED = 'KEYBOARD_TOGGLED';

export const AD_SWAP = 'AD_SWAP';

export const DOCUMENT_VISIBLE = 'DOCUMENT_VISIBLE';

export const KLIPSOLEADS_SET_DISCLAIMER_STATUS = 'KLIPSOLEADS_SET_DISCLAIMER_STATUS';
export const KLIPSOLEADS_REGISTER_SUCCESS = 'KLIPSOLEADS_REGISTER_SUCCESS';
export const KLIPSOLEADS_SET_SORTED_BY_COMPANY = 'KLIPSOLEADS_SET_SORTED_BY_COMPANY';
export const KLIPSOLEADS_CONTACTS_UPDATED = 'KLIPSOLEADS_CONTACTS_UPDATED';
export const KLIPSOLEADS_SET_SYNC_ONGOING = 'KLIPSOLEADS_SET_SYNC_ONGOING';
export const KLIPSOLEADS_RESET_LICENCE = 'KLIPSOLEADS_RESET_LICENCE';

export const BADGE_SCAN_WITHOUT_RESULT = 'BADGE_SCAN_WITHOUT_RESULT';

export const CONTACTS_SCAN_STARTED = 'CONTACTS_SCAN_STARTED';
export const SHOW_CONTACT_FORM = 'SHOW_CONTACT_FORM';
export const CONTACTS_UPDATED = 'CONTACTS_UPDATED';
export const CONTACTS_SCAN_UNAUTHORISED = 'CONTACTS_SCAN_UNAUTHORISED';

export const CODIFICATIONS_FETCHED = 'CODIFICATIONS_FETCHED';

export const SHOW_ONE_POI_ON_MAP_WITHOUT_NAVIGATION = 'SHOW_ONE_POI_ON_MAP_WITHOUT_NAVIGATION';

export const PERFORM_LOGIN_KLIPSO_SSO = 'PERFORM_LOGIN_KLIPSO_SSO';
export const SET_LOGIN_SSO_STATUS = 'SET_LOGIN_SSO_STATUS';

export const FETCH_HAPPENINGS_FEED = 'FETCH_HAPPENINGS_FEED';

export const SHOW_MODAL_PRIVACY_AGREEMENT = 'SHOW_MODAL_PRIVACY_AGREEMENT';
export const VALIDATE_MODAL_PRIVACY_AGREEMENT = 'VALIDATE_MODAL_PRIVACY_AGREEMENT';

export const  PERFORM_LOGIN_OAUTH2 = 'PERFORM_LOGIN_OAUTH2';
export const SET_LOGIN_OAUTH2_STATUS = 'SET_LOGIN_OAUTH2_STATUS';
export const PERFORM_LOGOUT_OAUTH2 = 'PERFORM_LOGOUT_OAUTH2';
export const GET_TOKEN_OAUTH2 = 'GET_TOKEN_OAUTH2';
export const REFRESH_TOKEN_OAUTH2 = 'REFRESH_TOKEN_OAUTH2';
