/**
 * Declare here a unique identifier for every page component.
 *
 * This identifier has various usage, e.g:
 *   - routing (of course)
 *   - store entry point
 *   - open/close menu (as there is 1 instance per page)
 *   - misc configuration like:
 *       - toolbar configuration
 *       - the 'two columns' mode
 *   etc
 */

const AIRCRAFT_PAGE_KEY = 'Aircraft';
const CHOOSE_PROFILE_PAGE_KEY = 'ChooseProfile';
const DOCUNIT_PAGE_KEY = 'Docunit';
const EVENT_PAGE_KEY = 'Event';
const EXHIBITOR_PAGE_KEY = 'Exhibitor';
const EXPERIENCE_ITEM_PAGE_KEY = 'ExperienceItem';
const FAVORITES_PAGE_KEY = 'Favorites';
const FLIGHTS_SCHEDULE_PAGE_KEY = 'FlightsSchedule';
const HOME_PAGE_KEY = 'Home';
const HOURS_PAGE_KEY = 'Hours';
const INBOX_PAGE_KEY = 'Inbox';
const KLIPSO_LEADS_PAGE_KEY = 'KlipsoLeads';
const LIST_GROUPS_PAGE_KEY = 'ListGroups';
const LIST_PAGE_KEY = 'List';
const LOGIN_PAGE_KEY = 'Login';
const MAP_PAGE_KEY = 'Map';
const MEDIAS_PAGE_KEY = 'Medias';
const MONUMENT_PAGE_KEY = 'Monument';
const HAPPENINGS_PAGE_KEY = 'Happening';
const NEWPRODUCT_PAGE_KEY = 'NewProduct';
const NOTES_PAGE_KEY = 'notes';
const PAPER_PAGE_KEY = 'Paper';
const PARTICIPANT_PAGE_KEY = 'Participant';
const CONTACT_PAGE_KEY = 'Contact';
const PMR_PAGE_KEY = 'Pmr';
const RESTAURANT_PAGE_KEY = 'Restaurant';
const SEARCH_PAGE_KEY = 'Search';
const SEARCH_TAIGA_PAGE_KEY = 'SearchTaiga';
const SERVICE_PAGE_KEY = 'Service';
const SPEAKER_PAGE_KEY = 'Speaker';
const SYNOPTIC_AGENDA_PAGE_KEY = 'SynopticAgenda';
const USER_DATA_PAGE_KEY = 'UserData';
const GMAP_PLACES_PAGE_KEY = 'GmapPlaces';
const LIVE_STREAMING_PAGE_KEY = 'LiveStream';
const UNAUTHORIZED_PAGE_KEY = 'Unauthorized';

module.exports = {
  AIRCRAFT_PAGE_KEY,
  CHOOSE_PROFILE_PAGE_KEY,
  DOCUNIT_PAGE_KEY,
  EVENT_PAGE_KEY,
  EXHIBITOR_PAGE_KEY,
  EXPERIENCE_ITEM_PAGE_KEY,
  FAVORITES_PAGE_KEY,
  FLIGHTS_SCHEDULE_PAGE_KEY,
  HOME_PAGE_KEY,
  HOURS_PAGE_KEY,
  INBOX_PAGE_KEY,
  KLIPSO_LEADS_PAGE_KEY,
  LIST_GROUPS_PAGE_KEY,
  LIST_PAGE_KEY,
  LOGIN_PAGE_KEY,
  MAP_PAGE_KEY,
  MEDIAS_PAGE_KEY,
  MONUMENT_PAGE_KEY,
  HAPPENINGS_PAGE_KEY,
  NEWPRODUCT_PAGE_KEY,
  NOTES_PAGE_KEY,
  PAPER_PAGE_KEY,
  PARTICIPANT_PAGE_KEY,
  CONTACT_PAGE_KEY,
  PMR_PAGE_KEY,
  RESTAURANT_PAGE_KEY,
  SEARCH_PAGE_KEY,
  SEARCH_TAIGA_PAGE_KEY,
  SERVICE_PAGE_KEY,
  SPEAKER_PAGE_KEY,
  SYNOPTIC_AGENDA_PAGE_KEY,
  USER_DATA_PAGE_KEY,
  GMAP_PLACES_PAGE_KEY,
  LIVE_STREAMING_PAGE_KEY,
  UNAUTHORIZED_PAGE_KEY
};
