module.exports = require("deepmerge")(require("./config.default.js"), {
  native: {
    name: {
      default: "SOFCOT_D"
    },
    id: "com.mobilespot.sofcot.dev",
    // iOS
    appleTeamId: "2BAP3P29V2",
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ""
  },
  web: {
    url: "sofcot2020-dev.mobile-spot.com"
  },
  codePush: {
    android: {
      CodePushDeploymentKey: ""
    },
    iOS: {
      CodePushDeploymentKey: ""
    }
  }
});